import { PRODUCT_TYPE, ORDER_REQUEST_TYPE } from '~/constants/orderTypes.js'

export default {
    isProductMode(state) {
        return state.currentType === PRODUCT_TYPE
    },
    isRequestMode(state) {
        return state.currentType === ORDER_REQUEST_TYPE
    },
}
