import { render, staticRenderFns } from "./Document.vue?vue&type=template&id=44f5d700&scoped=true"
var script = {}
import style0 from "./Document.vue?vue&type=style&index=0&id=44f5d700&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44f5d700",
  null
  
)

export default component.exports