
import { gql } from 'nuxt-graphql-request'

export default {
    data() {
        return {
            layout: '',
        }
    },
    async fetch() {
        const query = gql`
            query {
                ContentEntity(page: "layout", language: "${this.$cookies.get('cookie-lang')}") {
                    contententities {
                        id
                        type
                        name
                        attributes {
                            ...attr
                        }
                        subItems {
                            ...sub
                            subItems {
                                ...sub
                                subItems {
                                    ...sub
                                }
                            }
                        }
                    }
                }
            }

            fragment attr on Attributes {
                type
                name
                value
            }

            fragment sub on SingleContentEntity {
                type
                name
                attributes {
                    ...attr
                }
            }
        `
        const graphQL = await this.$graphql.default.request(query)
        this.$set(this, 'layout', graphQL)
    },
}
