import axios from 'axios'

/* eslint-disable @typescript-eslint/no-unused-vars */
export default ({ app }, inject) => {
    inject('downloadFile', downloadFile)
    inject('createDownloadName', createDownloadName)
}

function downloadFile(downloadUrl, downloadName) {
    const stringUtils = new this.$stringUtils(downloadName)
    axios
        .get(downloadUrl, {
            responseType: 'blob',
            headers: this.$getDownloadAuthRequestHeaders()
        })
        .then((response) => {
            const mimeType = 'application/pdf'
            const blob = new Blob([response.data], { type: mimeType })
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = stringUtils.addFileExtension(mimeType)
            link.click()
            URL.revokeObjectURL(link.href)
        })
        .catch(function (error) {
            alert(error)
        })
}

function createDownloadName(textOne, textTwo) {
    let one = ''
    let two = ''
    if (textOne) {
        /* eslint-disable no-useless-escape */
        one = textOne.replace(/[\/!*'();:@&=+$,/?%#]+/gi, '')
    }
    if (textTwo) {
        two = textTwo.replace(/\//gi, '')
    }
    if (textOne && textTwo) {
        return `${one} - ${two}`
    } else if (textOne) {
        return `${one}`
    } else if (textTwo) {
        return `${two}`
    }
}
