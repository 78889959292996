/* eslint-disable @typescript-eslint/no-unused-vars */
export default ({ app }, inject) => {
    inject('getItemTextValue', getItemTextValue)
    inject('getItemValue', getItemValue)
}

function getItemTextValue(item) {
    return item.attributes.find((a) => a.name === 'itemText')?.value
}

function getItemValue(item) {
    return item.attributes.find((a) => a.name === 'itemValue')?.value
}