
export default {
    props: {
        content: '',
        open: false,
        handleButton: '',
        timeout: 0,
        arrowPosition: '',
    },
    data() {
        return {
            isOpen: this.open,
        }
    },
    watch: {
        open(val) {
            this.isOpen = val

            if (this.isOpen && this.timeout > 0) {
                setTimeout(() => {
                    this.$nuxt.$emit('closeNotification')
                }, this.timeout)
            }
        },
    },
    computed: {
        computedArrowPosition() {
            let position = ''

            if (this.arrowPosition === 'center') {
                position = '--center'
            }

            return position
        },
    },
    methods: {
        handleClose() {
            this.isOpen = false

            this.$nuxt.$emit('closeNotification')
        },
        handleClickButton() {
            this.handleButton()
            this.handleClose()
        },
    },
}
