
import SideModal from './SideModal.vue'
import ContactForm from './ContactForm.vue'
import ContactFormMessage from './ContactFormMessage.vue'

export default {
    components: { SideModal, ContactForm, ContactFormMessage },
    props: {
        dynamicData: '',
    },
    data() {
        return {
            label: '',
            modalTitle: '',
            realData: '',
            buttonLabels: '',
            flyoutDetails: '',
            response: '',
        }
    },
    watch: {
        dynamicData(val) {
            this.setContent(val)
        },
    },
    computed: {
        canShowMessage() {
            return this.response?.success?.flag || this.response?.error?.flag
        },
    },
    created() {
        this.setContent(this.dynamicData)
    },
    methods: {
        setContent(dynamicData) {
            this.realData = new this.$customData(dynamicData)

            this.buttonLabels = this.realData.checkSubsType('contactFormButtonLabels')
            this.flyoutDetails = this.realData.checkSubsType('flyoutDetails')

            this.label = this.$getItemTextValue(this.buttonLabels.subItems[0])
            this.modalTitle = this.$getItemTextValue(this.flyoutDetails.subItems[1])
        },
        openModal() {
            this.resetModal()
            this.$refs.contactFormModal.openModal()
        },
        setResponse(response) {
            this.modalTitle = ''
            this.response = response

            setTimeout(() => {
                this.$refs.contactFormModal.closeModal()
            }, 3000)
        },
        resetModal() {
            this.response = {
                success: {
                    flag: false,
                    message: '',
                },
                error: {
                    flag: false,
                    message: '',
                },
            }

            this.modalTitle = this.$getItemTextValue(this.flyoutDetails.subItems[1])
        },
    },
}
