export default function (context) {
    if (context.$auth.$state.user?.Role) {
        if (context.route.params.id === 'order-documents' && !context.$auth.$state.user.Role.includes('hcp-orders')) {
            context.redirect('/Dashboard')
        } else if (context.route.params.id === 'orders' && !context.$auth.$state.user.Role.includes('hcp-orders')) {
            context.redirect('/Dashboard')
        } else if (context.route.params.id === 'order-statistics' && !context.$auth.$state.user.Role.includes('hcp-orders')) {
            context.redirect('/Dashboard')
        }
    }
}
