export default async function (context) {
    if (process.client) {
        if (context.$auth.$state.loggedIn) {
            // user is not authorized
            if (!context.$hasUserAccess()) {
                await context.$logoutNoAuth()
            }

            // set account number from cookies
            if (!context.store.state.account.accountNumber && context.$cookies.get('accountNumber')) {
                context.store.commit('account/setAccountNumber', context.$cookies.get('accountNumber'))
            }
        }
    }
}
