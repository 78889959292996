export default async function (context) {
    if (context.store.state.account.accountNumber) {
        context.redirect('/Dashboard')
    }

    await hasOnlyOneAccountNumber(context)
}

function hasOnlyOneAccountNumber(context) {
    if (context.$auth.$state.user?.EntityName === 'Contact') {
        if (context.$auth.$state.user?.AccountNumbers.length === 1) {
            context.$setCookie('accountNumber', context.$auth.$state.user?.AccountNumbers[0])
            context.store.commit('account/setAccountNumber', context.$auth.$state.user?.AccountNumbers[0])
            if (context.route.path.toLowerCase() !== '/dashboard') {
                context.redirect('/Dashboard')
            }
        }
    }
}
