
export default {
    props: {
        item: '',
    },
    data() {
        return {
            active: '',
        }
    },
    created() {
        this.$nuxt.$on('openSubMenu', (name) => {
            if (this.item.name === name) {
                this.active === 'navItem__item-active' ? (this.active = '') : (this.active = 'navItem__item-active')
            }
        })
    },
}
