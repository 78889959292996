
import CartIcon from '../../Svgs/CartIcon.vue'
import Notification from '../../Components/Notification.vue'

export default {
    components: { CartIcon, Notification },
    props: {
        dynamicData: '',
    },
    data() {
        return {
            initialNotificationContent: {
                title: '',
                description: '',
                buttonLabel: '',
                buttonRoute: '',
            },
            realData: '',
        }
    },
    watch: {
        dynamicData(val) {
            this.setContent(val)
        },
    },
    computed: {
        basketAmount() {
            return this.$store.state.basket.productsAmount + this.$store.state.basket.orderRequestsAmount
        },
        notificationContent() {
            if (this.visibleNotification) {
                const lastItem = this.$store.state.basket.lastAddedItem

                return {
                    ...this.initialNotificationContent,
                    title: lastItem?.ItemName ?? '',
                }
            }

            return this.initialNotificationContent
        },
        visibleNotification() {
            return this.$store.state.basket.visibleNotification
        },
    },
    created() {
        this.setContent(this.dynamicData)

        this.$nuxt.$on('closeNotification', () => {
            this.$store.commit('basket/setVisibleNotification', false)
        })

        if (process.client) {
            const basketStateJSON = sessionStorage.getItem('basket')

            if (basketStateJSON) {
                const basketState = JSON.parse(basketStateJSON)
                this.$store.commit('basket/setBasket', basketState)
            }

            const checkoutStateJSON = sessionStorage.getItem('checkout')

            if (checkoutStateJSON) {
                const checkoutState = JSON.parse(checkoutStateJSON)
                this.$store.commit('checkout/setCheckout', checkoutState)
            }
        }
    },
    methods: {
        setContent(data) {
            this.realData = new this.$customData(data)
            this.initialNotificationContent.description = this.realData.V('notificationDescription')
            this.initialNotificationContent.buttonLabel = this.realData.V('notificationButtonLabel')
            this.initialNotificationContent.buttonRoute = this.realData.V('notificationButtonRoute')
        },
        handleClick() {
            this.$router.push(this.initialNotificationContent.buttonRoute)
        },
    },
}
