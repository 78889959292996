import { gql } from 'nuxt-graphql-request'

const customerQuery = gql`
    query customerQuery($accountId: ID) {
        ListDataEntity(accountId: $accountId, entityType: "customer") {
            dataentities {
                id
                attributes {
                    name
                    value
                    type
                }
            }
        }
    }
`

export default customerQuery
