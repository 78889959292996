export default ({ _app }, inject) => {
    inject('dataLayerPush', dataLayerPush)
}

const dataLayerPush = (obj) => {
    const dataLayer = window.dataLayer

    const newObj = {
        event: obj.event ?? undefined,
        ...obj,
    }

    const pushedObj = Object.keys(newObj).reduce((acc, key) => {
        return !newObj[key] ? { ...acc } : { ...acc, [key]: newObj[key] }
    }, {})

    dataLayer.push(pushedObj)
}
