import Vue from 'vue'
import { VTooltip, Tooltip } from 'floating-vue'

Vue.directive('tooltip', VTooltip)
Vue.component('VTooltip', Tooltip)

Vue.directive('custom-tooltip', {
    bind: (el) => {
        el.addEventListener('mouseover', function (evt) {
            const targetEl = evt.target
            if (targetEl.offsetWidth < targetEl.scrollWidth) {
                targetEl.setAttribute('data-title', evt.target.textContent)
            } else {
                targetEl.hasAttribute('data-title') && targetEl.removeAttribute('data-title')
            }
        })
    },
})

Vue.filter('safename', function (value) {
    if (!value) return ''
    const filename = value.replace(/[^a-z0-9]/gi, '_').toLowerCase()
    return filename
})
