import { render, staticRenderFns } from "./ContactFormBadge.vue?vue&type=template&id=7aea8964&scoped=true"
import script from "./ContactFormBadge.vue?vue&type=script&lang=js"
export * from "./ContactFormBadge.vue?vue&type=script&lang=js"
import style0 from "./ContactFormBadge.vue?vue&type=style&index=0&id=7aea8964&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7aea8964",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ContactFormMessage: require('C:/app/components/Components/ContactFormMessage.vue').default,ContactForm: require('C:/app/components/Components/ContactForm.vue').default,SideModal: require('C:/app/components/Components/SideModal.vue').default})
