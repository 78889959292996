export const initialState = {
    deliveryDate: '',
    productsAmount: 0,
    orderRequestsAmount: 0,
    products: [],
    orderRequests: [],
    visibleNotification: false,
    lastAddedItem: undefined,
    shouldRefreshPrices: false,
    acceptedTerms: false,
}

export default () => {
    return initialState
}
