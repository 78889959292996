import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=2627589e&scoped=true"
import script from "./Footer.vue?vue&type=script&lang=js"
export * from "./Footer.vue?vue&type=script&lang=js"
import style0 from "./Footer.vue?vue&type=style&index=0&id=2627589e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2627589e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ImageSizesQL: require('C:/app/components/Helpers/ImageSizesQL.vue').default,BackgroundLink: require('C:/app/components/Helpers/BackgroundLink.vue').default,Youtube: require('C:/app/components/Svgs/Youtube.vue').default,Facebook: require('C:/app/components/Svgs/Facebook.vue').default,LinkedIn: require('C:/app/components/Svgs/LinkedIn.vue').default,Twitter: require('C:/app/components/Svgs/Twitter.vue').default,Footer: require('C:/app/components/Footer/Footer.vue').default})
