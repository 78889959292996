
export default {
    props: {
        footer: '',
        cookie: '',
    },
    data() {
        return {
            bgClass: 'cover',
            logoClass: 'contain',
        }
    },
}
