import moment from 'moment'
import { calculateSubtotal, compareByInternalItemNo } from './helpers.js'
import { PRODUCT_TYPE, ORDER_REQUEST_TYPE } from '~/constants/orderTypes.js'
import { WP } from '~/constants/packagingTerms.js'
import customerQuery from '~/graphql/queries/customer-query.js'
import productsQuery from '~/graphql/queries/products-query.js'

export default {
    async addItem(context, { type, item }) {
        if (!context.state.deliveryDate) {
            return
        }

        const currentItem = { ...item }

        let canPallet = false
        let canPalletInitial = false
        let hasFreightInBasePrice = false
        let cl1 = false
        let vatno = ''

        const customerGraphQL = await this.$graphql.default.request(customerQuery, { accountId: currentItem.CustomerNumber }, this.$getAuthRequestHeader())
        if (customerGraphQL?.ListDataEntity?.dataentities && customerGraphQL?.ListDataEntity?.dataentities.length > 0) {
            const customerEntities = new this.$dataEntities(customerGraphQL?.ListDataEntity?.dataentities)
            canPalletInitial = currentItem?.canPallet?.toLowerCase() === 'true'
            canPallet = canPalletInitial && customerEntities.entities[0].PackagingTerms === WP
            hasFreightInBasePrice = customerEntities.entities[0].HasFreightInBasePrice
            cl1 = customerEntities.entities[0].CL1
            vatno = customerEntities.entities[0].Vatno
        }

        currentItem.canPallet = canPallet
        currentItem.canPalletInitial = canPalletInitial
        currentItem.HasFreightInBasePrice = hasFreightInBasePrice
        currentItem.CL1 = cl1
        currentItem.Vatno = vatno

        if (type === PRODUCT_TYPE) {
            context.commit('addProduct', currentItem)
        }

        if (type === ORDER_REQUEST_TYPE) {
            context.commit('addOrderRequest', currentItem)
        }

        context.commit('setVisibleNotification', true)
    },
    async refreshPrices(context) {
        const productsInternalNumbers = context.state.products.map((p) => p.InternalItemNo)
        const orderRequestsInternalNumbers = context.state.orderRequests.map((p) => p.InternalItemNo)
        const internalNumbers = [...productsInternalNumbers, ...orderRequestsInternalNumbers]

        if (internalNumbers.length > 0) {
            const queryInput = {
                accountId: context.rootState.account.accountNumber,
                page: 1,
                pageSize: 100,
                orderBy: 'InternalItemNo DESC',
                searchFilter: `PriceDate:${moment(context.state.deliveryDate).format('YYYY-MM-DD')}||InternalItemNo:${internalNumbers.join(',')}||Type:Standard`,
            }

            const productsGraphQL = await this.$graphql.default.request(productsQuery, queryInput, this.$getAuthRequestHeader())
            if (productsGraphQL?.ListDataEntity?.dataentities && productsGraphQL?.ListDataEntity?.dataentities.length > 0) {
                const productsEntities = new this.$dataEntities(productsGraphQL?.ListDataEntity?.dataentities)

                productsEntities.entities.forEach((productEntity) => {
                    if (productEntity.BasePrice && productsInternalNumbers.includes(productEntity.InternalItemNo)) {
                        context.dispatch('checkPrice', productEntity)
                    }

                    if (productEntity.BasePrice && orderRequestsInternalNumbers.includes(productEntity.InternalItemNo)) {
                        context.dispatch('moveToProducts', productEntity)
                    }

                    if (!productEntity.BasePrice && productsInternalNumbers.includes(productEntity.InternalItemNo)) {
                        context.dispatch('moveToOrderRequests', productEntity)
                    }
                })
            }
        }

        context.commit('setShouldRefreshPrices', false)
    },
    checkPrice(context, item) {
        const currentItem = context.state.products.find((p) => compareByInternalItemNo(p, item))

        if (currentItem) {
            const shouldUpdate = currentItem.BasePrice !== item.BasePrice || currentItem.Currency !== item.Currency || currentItem.Unit !== item.Unit || (parseInt(currentItem.Surcharge) || 0) !== (parseInt(item.Surcharge) || 0)

            if (shouldUpdate) {
                context.commit('updateProductPrice', item)
            }
        }
    },
    moveToProducts(context, item) {
        const currentItem = context.state.orderRequests.find((or) => compareByInternalItemNo(or, item))

        if (currentItem) {
            const currentItemCopy = JSON.parse(JSON.stringify(currentItem))

            currentItemCopy.BasePrice = item.BasePrice
            currentItemCopy.Currency = item.Currency
            currentItemCopy.Unit = item.Unit
            currentItemCopy.Surcharge = item.Surcharge

            context.commit('addProduct', currentItemCopy)
            context.commit('removeOrderRequest', currentItem)
        }
    },
    moveToOrderRequests(context, item) {
        const currentItem = context.state.products.find((p) => compareByInternalItemNo(p, item))

        if (currentItem) {
            const currentItemCopy = JSON.parse(JSON.stringify(currentItem))

            currentItemCopy.BasePrice = null
            currentItemCopy.Currency = null
            currentItemCopy.Unit = null
            currentItemCopy.Surcharge = null

            context.commit('addOrderRequest', currentItemCopy)
            context.commit('removeProduct', currentItem)
        }
    },
    recalculateSubtotal(context, { index, key }) {
        const item = context.state.products[index]
        if (item) {
            const newSubtotal = calculateSubtotal(item)
            context.commit('changeProductValue', { index, key, payload: newSubtotal })
        }
    },
    productLines(context) {
        const productLines = {}

        for (const product of context.state.products) {
            if (product.CustomerNumber in productLines) {
                productLines[product.CustomerNumber].push(product)
            } else {
                productLines[product.CustomerNumber] = [product]
            }
        }

        return productLines
    },
    orderRequestLines(context) {
        const orderRequestLines = {}

        for (const orderRequest of context.state.orderRequests) {
            if (orderRequest.CustomerNumber in orderRequestLines) {
                orderRequestLines[orderRequest.CustomerNumber].push(orderRequest)
            } else {
                orderRequestLines[orderRequest.CustomerNumber] = [orderRequest]
            }
        }

        return orderRequestLines
    },
    changeDeliveryDate(context, newDate) {
        if (newDate !== context.state.deliveryDate) {
            context.commit('setShouldRefreshPrices', true)
        }

        context.commit('setDeliveryDate', newDate)
    },
}
