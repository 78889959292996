import { render, staticRenderFns } from "./BackgroundLink.vue?vue&type=template&id=22f4e00c&scoped=true"
import script from "./BackgroundLink.vue?vue&type=script&lang=js"
export * from "./BackgroundLink.vue?vue&type=script&lang=js"
import style0 from "./BackgroundLink.vue?vue&type=style&index=0&id=22f4e00c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22f4e00c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ImageSizesQL: require('C:/app/components/Helpers/ImageSizesQL.vue').default})
