import { gql } from 'nuxt-graphql-request'

const productsQuery = gql`
    query productsQuery($accountId: ID, $page: Int, $pageSize: Int, $orderBy: String, $searchFilter: String, $grouped: String) {
        ListDataEntity(accountId: $accountId, entityType: "products", page: $page, pageSize: $pageSize, orderBy: $orderBy, searchFilter: $searchFilter, grouped: $grouped) {
            datadefinition
            pagination {
                count
                page
                totalcount
                pagesize
            }
            dataentities {
                id
                type
                attributes {
                    name
                    value
                    type
                }
            }
        }
    }
`

export default productsQuery
