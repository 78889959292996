
export default {
    props: {
        height: '',
        transparent: false,
    },
    data() {
        return {
            isHeight: '',
        }
    },
    computed: {
        isTransparent() {
            return this.transparent ? 'loader-bg-transparent' : ''
        },
    },
    created() {
        if (this.height === true) {
            this.isHeight = 'loader__full'
        } else if (this.height === false) {
            this.isHeight = 'loader__defined'
        } else if (this.height === 'small') {
            this.isHeight = 'loader__small'
        }
    },
}
