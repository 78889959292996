
import CartToggle from '../Shop/Cart/CartToggle.vue'

import { ROLE_ESHOP } from '~/constants/roles.js'

export default {
    components: { CartToggle },
    props: {
        navigation: '',
        dynamicData: '',
    },
    data() {
        return {
            logoClass: 'contain',
            isActive: false,
            isActiveProfile: false,
            scrollPosition: null,
            accountnumber: this.$store.state.account.accountNumber,
            roles: this.$auth.user?.Role,
            profileName: `${this.$auth.user?.FirstName || ''} ${this.$auth.user?.LastName || ''}`,
            pagesWithoutBackground: ['casip', 'cart', 'checkout'],
        }
    },
    computed: {
        hasSelectedAccount() {
            return this.$store.state.account.hasSelectedAccount
        },
        isPageWithoutBackground() {
            return this.pagesWithoutBackground.some((p) => this.$route.path.toLowerCase().includes(p))
        },
    },
    created() {
        this.$nuxt.$on('openSubMenu', () => {
            this.isActive === true ? (this.isActive = false) : (this.isActive = true)
        })
        this.$nuxt.$on('openProfile', () => {
            this.isActiveProfile === true ? (this.isActiveProfile = false) : (this.isActiveProfile = true)
        })
    },
    mounted() {
        window.addEventListener('scroll', this.updateScroll)
    },
    methods: {
        updateScroll() {
            this.scrollPosition = window.scrollY
        },
        checkRole(item, roles) {
            if (item.attributes[0].value.replace('/', '') === 'Products' && roles?.includes('hcp-orders')) {
                return true
            } else if (item.attributes[0].value.replace('/', '') === 'Statement-account' && roles?.includes('hcp-invoices')) {
                return true
            } else if (item.attributes[0].value.replace('/', '') === 'Orders' && roles?.includes('hcp-orders')) {
                return true
            } else if (item.attributes[0].value.replace('/', '') === 'Casip' && roles?.includes('hcp_casip_view')) {
                return true
            } else if (item.attributes[0].value.replace('/', '') === 'Pricelists') {
                return true
            } else if (item.attributes[0].value.replace('/', '') === 'eOrder' && this.hasAccessToShop(roles)) {
                return true
            }

            return false
        },
        hasAccessToShop(roles) {
            return roles?.includes(ROLE_ESHOP)
        },
    },
}
