
import { ROLE_ESHOP } from '~/constants/roles.js'

import contactsQuery from '~/graphql/queries/contacts-query.js'
import addMessageMutation from '~/graphql/mutations/add-message-mutation.js'

export default {
    props: {
        dynamicData: '',
        setResponse: '',
    },
    data() {
        return {
            sendLabel: '',
            question: {
                label: '',
                placeholder: '',
                model: '',
                hasError: false,
            },
            message: {
                label: '',
                placeholder: '',
                model: '',
                hasError: false,
            },
            buttonLabels: '',
            flyoutDetails: '',
            responseMessages: {
                success: '',
                error: '',
            },
            invokedForm: false,
        }
    },
    watch: {
        dynamicData(val) {
            this.setContent(val)
        },
    },
    async fetch() {
        const contacts = this.$store.state.account.contacts
        if (!contacts || contacts?.length === 0) {
            await this.$graphql.default
                .request(contactsQuery, { accountId: this.$store.state.account.accountNumber }, this.$getAuthRequestHeader())
                .then((response) => {
                    this.$store.commit('account/setContacts', response.ListDataEntity.dataentities)
                })
                .catch((e) => {
                    console.log(e)
                })
        }
    },
    created() {
        this.setContent(this.dynamicData)
    },
    methods: {
        setContent(dynamicData) {
            this.realData = new this.$customData(dynamicData)

            this.buttonLabels = this.realData.checkSubsType('contactFormButtonLabels')
            this.flyoutDetails = this.realData.checkSubsType('flyoutDetails')

            this.responseMessages.success = this.realData.V('contactFormSuccessMessage')
            this.responseMessages.error = this.realData.V('contactFormFailureMessage')

            this.sendLabel = this.$getItemTextValue(this.buttonLabels.subItems[1])
            this.question.label = this.$getItemTextValue(this.flyoutDetails.subItems[4])
            this.question.placeholder = this.$getItemTextValue(this.flyoutDetails.subItems[5])
            this.message.label = this.$getItemTextValue(this.flyoutDetails.subItems[2])
            this.message.placeholder = this.$getItemTextValue(this.flyoutDetails.subItems[3])
        },
        sanitizeString(inputString) {
            // remove HTML tags and attributes
            inputString = inputString.replace(/<[^>]*>?/gm, '');

            // remove SQL injection characters
            inputString = inputString.replace(/['";:\(\){}=\+\-\[\]]/gi, '');

            return inputString;
        },
        getContactEmail(role) {
            let contact = ''

            if (this.$store.state.account.contacts?.length === 0) {
                return contact
            }

            const foundContact = this.$store.state.account.contacts.find((sc) => sc.Role === role)
            if (foundContact) {
                contact = foundContact
            }

            return contact?.Email ?? ''
        },
        async sendForm() {
            this.invokedForm = true
            this.question.hasError = this.question.model.length === 0
            this.message.hasError = this.message.model.length === 0

            if (this.question.hasError || this.message.hasError) {
                return
            }

            const response = {
                success: {
                    flag: false,
                    message: this.responseMessages.success,
                },
                error: {
                    flag: false,
                    message: this.responseMessages.error,
                },
            }

            const mutationInput = {
                accountNumber: `${this.$store.state.account.accountNumber}`,
                shopAccess: `${this.$auth.user?.Role.includes(ROLE_ESHOP)}`,
                salesRep: `${this.getContactEmail('salesrep')}`,
                contactName: `${this.$store.state.account.accountName}`,
                contactEmail: `${this.$auth.user?.EmailAddress}`,
                title: `${this.sanitizeString(this.question.model)}`,
                description: `${this.sanitizeString(this.message.model)}`,
                responsible: `${this.getContactEmail('responsible')}`,
                owner: `${this.getContactEmail('owner')}`,
            }

            await this.$graphql.default
                .request(addMessageMutation, { input: mutationInput }, this.$getAuthRequestHeader())
                /* eslint-disable @typescript-eslint/no-unused-vars */
                .then((value) => {
                    if (value?.addMessage?.accountNumber) {
                        response.success.flag = true
                    } else {
                        response.error.flag = true
                    }
                    
                    this.setResponse(response)
                    this.invokedForm = false
                })
                /* eslint-disable @typescript-eslint/no-unused-vars */
                .catch((e) => {
                    response.error.flag = true
                    this.setResponse(response)
                    this.invokedForm = false
                })
        },
    },
}
