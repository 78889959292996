
export default {
    props: {
        data: '',
    },
    data() {
        return {
            bgClass: 'cover',
        }
    },
    methods: {
        checkColor(color) {
            return `link__text-${color}`
        },
    },
}
