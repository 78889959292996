import { render, staticRenderFns } from "./CartToggle.vue?vue&type=template&id=54d3d542"
import script from "./CartToggle.vue?vue&type=script&lang=js"
export * from "./CartToggle.vue?vue&type=script&lang=js"
import style0 from "./CartToggle.vue?vue&type=style&index=0&id=54d3d542&prod&oped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CartIcon: require('C:/app/components/Svgs/CartIcon.vue').default,Notification: require('C:/app/components/Components/Notification.vue').default})
