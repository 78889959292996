import { render, staticRenderFns } from "./ContactFormMessage.vue?vue&type=template&id=5db53add&scoped=true"
import script from "./ContactFormMessage.vue?vue&type=script&lang=js"
export * from "./ContactFormMessage.vue?vue&type=script&lang=js"
import style0 from "./ContactFormMessage.vue?vue&type=style&index=0&id=5db53add&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5db53add",
  null
  
)

export default component.exports