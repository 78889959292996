export const initialState = {
    deliveryAddressViewModel: undefined,
    deliveryAddressEntity: undefined,
    deliveryAddressIndex: -1,
    invoiceAddressEntity: undefined,
    currentType: '',
    warningBox: {
        visible: false,
        content: '',
    },
}

export default () => initialState
