
import { gql } from 'nuxt-graphql-request'

import ContactFormBadge from '../components/Components/ContactFormBadge.vue'
import dynamicContentQuery from '~/graphql/queries/dynamic-content-query.js'

export default {
    middleware: ['beforeAuth', 'auth', 'afterAuth'],
    components: { ContactFormBadge },
    data() {
        return {
            layout: undefined,
            dynamicLayout: undefined,
        }
    },
    async fetch() {
        const query = gql`
            query {
                ContentEntity(page: "layout", language: "${this.$cookies.get('cookie-lang')}") {
                    contententities {
                        id
                        type
                        name
                        attributes {
                            ...attr
                        }
                        subItems {
                            ...sub
                            subItems {
                                ...sub
                                subItems {
                                    ...sub
                                }
                            }
                        }
                    }
                }
            }

            fragment attr on Attributes {
                type
                name
                value
            }

            fragment sub on SingleContentEntity {
                type
                name
                attributes {
                    ...attr
                }
            }
        `

        await this.$graphql.default
            .request(query, {}, this.$getAuthRequestHeader())
            .then((value) => {
                this.$set(this, 'layout', value)
                this.$store.commit('errorMessages/setEntity', this.layout.ContentEntity.contententities[4])
            })
            .catch((e) => {
                console.log(e)
            })

        await this.$graphql.default
            .request(dynamicContentQuery, { page: 'layout', language: this.$cookies.get('cookie-lang') }, this.$getAuthRequestHeader())
            .then((value) => {
                this.$set(this, 'dynamicLayout', value)
            })
            .catch((e) => {
                console.log(e)
            })
    },
    computed: {
        canDisplayContent() {
            return this.layout && this.dynamicLayout
        },
        hasLayoutContent() {
            return this.layout.ContentEntity.contententities.length > 0
        },
        hasDynamicLayoutContent() {
            return this.dynamicLayout.DynamicContentEntity.contententities.length > 0
        },
        showContactBadge() {
            return this.hasDynamicLayoutContent && this.$auth.$state.user?.EntityName !== 'SystemUser'
        },
    },
    head() {
        // https://medium.com/@mandeep_m91/how-to-add-third-party-scripts-inline-scripts-in-your-nuxt-js-app-98f36749a84f
        const scriptsArray = []
        const noScriptsArray = []
        if (this.layout) {
            const _settings = this.layout.ContentEntity.contententities.filter((x) => x.id === 'settings')[0]
            const inlineScripts = ['googleTagManager', 'googleTagManagerNoScript', 'cookiebotScript', 'cookiebotScriptContent']

            inlineScripts.forEach((inlineScript) => {
                const _scriptSetting = _settings.attributes.filter((x) => x.name === inlineScript)
                if (_scriptSetting && _scriptSetting.length > 0) {
                    const scriptName = _scriptSetting[0].name
                    const scriptValue = _scriptSetting[0].value
                    if (scriptValue.startsWith('http')) {
                        scriptsArray.push({
                            hid: scriptName,
                            src: scriptValue,
                            defer: true,
                        })
                    } else if (scriptName === 'googleTagManager') {
                        scriptsArray.push({
                            hid: 'gtm',
                            type: 'text/javascript',
                            innerHTML: scriptValue,
                            defer: true,
                        })
                    } else if (scriptName === 'googleTagManagerNoScript') {
                        // Renders to noscript array.
                        noScriptsArray.push({
                            hid: 'gtm_no-script',
                            type: 'text/javascript',
                            innerHTML: scriptValue,
                            body: true,
                        })
                    } else if (scriptValue.startsWith('<script')) {
                        const attributes = scriptValue.match(/[\w-]+="[^"]*"/g)

                        const clearAttributes = attributes.map((attr) => attr.replace(/"/g, ''))
                        const parameters = clearAttributes.map((cattr) => cattr.split('='))

                        const hid = parameters.find((p) => p[0] === 'id')
                        const src = parameters.find((p) => p[0] === 'src')
                        const scriptType = parameters.find((p) => p[0] === 'type')

                        scriptsArray.push({
                            hid: hid ? hid[1] : 'scriptName',
                            src: src ? src[1] : '',
                            type: scriptType ? scriptType[1] : 'text/javascript',
                            charset: 'utf-8',
                        })
                    } else {
                        scriptsArray.push({
                            hid: 'scriptName',
                            innerHTML: scriptValue,
                            type: 'text/javascript',
                            charset: 'utf-8',
                        })
                    }
                }
            })
        }

        return {
            __dangerouslyDisableSanitizers: ['script'],
            script: scriptsArray,
            noscript: noScriptsArray,
        }
    },
    beforeDestroy() {
        this.$nuxt.$off('changeLayoutLang')
    },
    created() {
        this.$nuxt.$on('loggedOut', () => {
            this.layout = ''
        })
        this.$nuxt.$on('changeLayoutLang', () => {
            this.$fetch()
        })
    },
}
