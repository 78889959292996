
export default {
    props: {
        response: '',
    },
    data() {
        return {}
    },
    created() {},
    methods: {},
}
