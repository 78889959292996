import { render, staticRenderFns } from "./Submenu.vue?vue&type=template&id=8bb0d54c&scoped=true"
import script from "./Submenu.vue?vue&type=script&lang=js"
export * from "./Submenu.vue?vue&type=script&lang=js"
import style0 from "./Submenu.vue?vue&type=style&index=0&id=8bb0d54c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8bb0d54c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Document: require('C:/app/components/Svgs/Document.vue').default,Statistics: require('C:/app/components/Svgs/Statistics.vue').default,OrderDoc: require('C:/app/components/Svgs/OrderDoc.vue').default})
