import moment from 'moment'
import { CIP, FCA, MIX } from '~/constants/consentTypes.js'

export const calculateSubtotal = (item) => {
    const weightRatio = item.Unit === item.PackageUnit ? 1 : item.Unit.toUpperCase() === 'TON' ? 0.001 : 1000
    const subtotal = Number((item.Price * item.Weight * weightRatio).toFixed(3))
    return subtotal
}

export const calculatePrice = (item) => {
    return item.BasePrice + (parseInt(item.Surcharge) || 0)
}

export const calculateOrderTotals = (products) => {
    const currencies = [...new Set(products.map((p) => p.Currency))]
    const totals = currencies.map((currency) => {
        return {
            Currency: currency,
            Total: 0,
        }
    })

    if (totals?.length === 0) {
        return []
    }

    products.forEach((product) => {
        const foundCurrencyIndex = totals.findIndex((total) => total.Currency === product.Currency)
        totals[foundCurrencyIndex].Total += product.Subtotal
    })

    return totals
}

export const getConsentType = (items) => {
    if (items.every((i) => i.HasFreightInBasePrice)) {
        return CIP
    }

    if (items.every((i) => !i.HasFreightInBasePrice)) {
        return FCA
    }

    return MIX
}

export const mapProductsToMutationSpec = (products) => {
    const result = []

    for (const product of products) {
        const mutationSpec = {
            itemNumber: `${product.InternalItemNo}`,
            itemName: `${product.ItemName}`,
            quantity: `${product.Weight}`,
            unit: `${product.PackageUnit}`,
            withPallet: product.canPallet,
            price: `${product.Subtotal}`,
            currency: `${product.Currency}`,
        }

        result.push(mutationSpec)
    }

    return result
}

export const mapOrderRequestToMutationSpec = (orderRequests) => {
    const result = []

    for (const orderRequest of orderRequests) {
        const mutationSpec = {
            itemNumber: `${orderRequest.InternalItemNo}`,
            itemName: `${orderRequest.ItemName}`,
            quantity: `${orderRequest.Weight}`,
            unit: `${orderRequest.PackageUnit}`,
            withPallet: orderRequest.canPallet,
        }

        result.push(mutationSpec)
    }

    return result
}

export const compareByItemName = (firstProduct, secondProduct) => {
    return firstProduct.ItemName === secondProduct.ItemName && firstProduct.CustomerNumber === secondProduct.CustomerNumber
}

export const compareByInternalItemNo = (firstProduct, secondProduct) => {
    return firstProduct.InternalItemNo === secondProduct.InternalItemNo && firstProduct.CustomerNumber === secondProduct.CustomerNumber
}

export const getPriceDateQuery = (priceDate) => {
    const formattedDate = priceDate ? moment(priceDate).format('YYYY-MM-DD') : undefined
    return formattedDate ? `PriceDate:${formattedDate}` : ''
}