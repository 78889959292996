export const state = () => ({
    productsAmount: 0,
    orderRequestsAmount: 0,
    products: [],
    orderRequests: [],
    orderItems: [],
})

export const mutations = {
    setProducts(state, value) {
        state.productsAmount = value.length
        state.products = value
    },
    setOrderRequests(state, value) {
        state.orderRequestsAmount = value.length
        state.orderRequests = value
    },
    removeProduct(state, value) {
        const filteredList = state.products.filter((i) => i.ItemName !== value.ItemName)
        state.products = filteredList
        state.productsAmount = filteredList.length
    },
    removeOrderRequest(state, value) {
        const filteredList = state.orderRequests.filter((i) => i.ItemName !== value.ItemName)
        state.orderRequests = filteredList
        state.orderRequestsAmount = filteredList.length
    },
    changeProductValue(state, { index, key, payload }) {
        state.products[index][key] = payload
    },
    changeOrderRequestValue(state, { index, key, payload }) {
        state.orderRequests[index][key] = payload
    },
    setOrderItems(state, value) {
        state.orderItems = value
    },
}
