/* eslint-disable @typescript-eslint/no-unused-vars */
export default ({ app }, inject) => {
    inject('setCookieLang', setCookieLang)
    inject('setCookie', setCookie)
    inject('removeCookie', removeCookie)
}

function setCookieLang(value) {
    this.$cookies.set('cookie-lang', value, {
        path: '/',
        maxAge: 60 * 60 * 24 * 7,
    })
}

function setCookie(name, value) {
    this.$cookies.set(name, value, {
        path: '/',
    })
}

function removeCookie(name) {
    this.$cookies.remove(name, {
        path: '/',
    })
}
