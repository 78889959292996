import { gql } from 'nuxt-graphql-request'

const dynamicContentQuery = gql`
    query dynamicContentQuery($page: ID, $language: String) {
        DynamicContentEntity(page: $page, language: $language) {
            datadefinition
            contententities {
                id
                type
                name
                attributes {
                    type
                    name
                    value
                }
                subItems {
                    type
                    name
                    attributes {
                        type
                        name
                        value
                    }
                    subItems {
                        type
                        name
                        attributes {
                            type
                            name
                            value
                        }
                        subItems {
                            type
                            name
                            attributes {
                                type
                                name
                                value
                            }
                            subItems {
                                type
                                name
                                attributes {
                                    type
                                    name
                                    value
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`

export default dynamicContentQuery
