import { getConsentType } from './helpers.js'

export default {
    productsM3Numbers(state) {
        return [...new Set(state.products.map((product) => product.CustomerNumber))]
    },
    orderRequestsM3Numbers(state) {
        return [...new Set(state.orderRequests.map((orderRequest) => orderRequest.CustomerNumber))]
    },
    productsConsentType(state) {
        return getConsentType(state.products)
    },
    orderRequestsConsentType(state) {
        return getConsentType(state.orderRequests)
    },
    productsVatNo(state) {
        return state.products.length > 0 && state.products.find((p) => p.Vatno) ? state.products.find((p) => p.Vatno).Vatno : ''
    },
    orderRequestsVatNo(state) {
        return state.orderRequests.length > 0 && state.orderRequests.find((or) => or.Vatno) ? state.orderRequests.find((or) => or.Vatno).Vatno : ''
    },
    canCheckoutProducts(state) {
        return state.deliveryDate && state.productsAmount > 0
    },
    canCheckoutRequests(state) {
        return state.deliveryDate && state.orderRequestsAmount > 0
    },
}
