import { gql } from 'nuxt-graphql-request'

const contactsQuery = gql`
    query contactsQuery($accountId: ID) {
        ListDataEntity(accountId: $accountId, entityType: "contacts") {
            dataentities {
                id
                attributes {
                    name
                    value
                    type
                }
            }
        }
    }
`

export default contactsQuery
