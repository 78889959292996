/* eslint-disable @typescript-eslint/no-unused-vars */
export default ({ app }, inject) => {
    inject('logout', logout)
    inject('logoutNoAuth', logoutNoAuth)
    inject('clearAppStorage', clearAppStorage)
    inject('hasUserAccess', hasUserAccess)
    inject('hasAuthCookies', hasAuthCookies)
    inject('getBearerAuthToken', getBearerAuthToken)
    inject('getAuthRequestHeader', getAuthRequestHeader)
    inject('getDownloadAuthRequestHeaders', getDownloadAuthRequestHeaders)
}

import { ID_TOKEN, ID_TOKEN_EXP, REFRESH_TOKEN, REFRESH_TOKEN_EXP } from '~/constants/authPropertyKeys.js'

async function logout() {
    this.$clearAppStorage()
    await this.$auth.logout()
}

async function logoutNoAuth() {
    this.$auth.strategies.oidc.options.logoutRedirectUri = process.env.OIDC_ACCESS_DENIED_URI
    await this.$logout()
}

function clearAppStorage() {
    this.$removeCookie('accountNumber')
    this.$removeCookie('accountName')
    sessionStorage.clear()

    if (this.$store) {
        this.$store.commit('account/resetAccount')
    }
}

function hasUserAccess() {
    return this.$auth.$state.user?.EntityName && (this.$auth.$state.user?.EntityName !== 'Contact' || this.$auth.$state.user?.AccountNumbers.length > 0)
}

function hasAuthCookies() {
    const authCookies = this.$auth.$storage.getCookies()
    const authPropertyKeys = [ID_TOKEN, ID_TOKEN_EXP, REFRESH_TOKEN, REFRESH_TOKEN_EXP]
    const hasAuthCookies = authCookies && authPropertyKeys.every((apk) => {
        var apkKey = `${apk}${this.$auth.options.defaultStrategy}`
        return authCookies.hasOwnProperty(apkKey) && authCookies[apkKey] !== 'false'
    })

    return hasAuthCookies
}

function getAuthRequestHeader() {
    return {
        authorization: this.$getBearerAuthToken()
    }
}

function getDownloadAuthRequestHeaders() {
    return {
        authorization: this.$getBearerAuthToken(),
        'Access-Control-Allow-Origin': '*'
    }
}

function getBearerAuthToken() {
    return `Bearer ${this.$auth.strategy.token.get()}`
}
