export const state = () => ({
    accountNumber: undefined,
    accountName: undefined,
    contacts: undefined,
    customerData: undefined,
    hasSelectedAccount: false,
})

export const mutations = {
    setAccountNumber(state, value) {
        state.accountNumber = value
        state.hasSelectedAccount = true
    },
    setAccountName(state, value) {
        state.accountName = value
        state.hasSelectedAccount = true
    },
    setContacts(state, value) {
        const contacts = value.map((c) => {
            const contact = {}
            c.attributes.forEach((attr) => {
                contact[attr.name] = attr.value
            })
            return contact
        })

        state.contacts = contacts
    },
    resetAccount(state) {
        state.accountName = undefined
        state.accountNumber = undefined
        state.hasSelectedAccount = false
    },
    setCustomerData(state, value) {
        state.customerData = value
        sessionStorage.setItem('customerData', JSON.stringify(value))
    },
}
