
export default {
    props: {
        image: '',
        imgClass: '',
    },
    data() {
        return {
            src: '',
            alt: '',
            srcSets: [],
            sizes: ['_400.jpg', '_800.jpg', '_1200.jpg'],
        }
    },
    created() {
        this.createSrcSets(this.image.url, this.sizes)
    },
    methods: {
        createSrcSets(source, size) {
            const sizes = []
            let initialSource = source
            size.forEach((e) => {
                const extractWidth = `${e.split('.')[0].split('_')[1]}`
                const changedSrc = `${initialSource}?w=${extractWidth} ${extractWidth}w`
                sizes.push(changedSrc)
                initialSource = source
            })
            this.srcSets = sizes.join(', ')
        },
        checkClass(imageClass) {
            return `image image__${imageClass}`
        },
    },
}
