const mime = require('mime-types')

/* eslint-disable @typescript-eslint/no-unused-vars */
export default ({ app }, inject) => {
    inject('customData', ComponentData)
    inject('stringUtils', StringUtils)
    inject('dataEntities', DataEntities)
    inject('dataEntity', DataEntity)
}
class ComponentData {
    constructor(properties) {
        this.properties = properties
    }

    // this goes through attributes

    V(check) {
        let value
        this.properties.attributes.forEach((item) => {
            if (item.name === check) {
                value = item.value
            }
        })
        return value
    }

    checkAttributes(checks) {
        const values = []
        this.properties.attributes.forEach((item) => {
            if (checks.includes(item.name)) {
                values.push(item)
            }
        })
        return values
    }
    // this checks type of the objects

    checkType(check) {
        let value
        this.properties.forEach((item) => {
            if (item.name === check) {
                value = item
            }
        })
        return value
    }

    checkSubsType(check) {
        let value
        this.properties.subItems.forEach((item) => {
            if (item.name === check) {
                value = item
            }
        })
        return value
    }

    checkSubTypes(checkOne, checkTwo) {
        const values = []
        this.properties.forEach((item) => {
            if (item.name === checkOne) {
                item.subItems.forEach((element) => {
                    if (element.type === checkTwo) {
                        values.push(element)
                    }
                })
            }
        })
        return values
    }

    checkSubAttributes(checkOne, checkTwo) {
        let value
        this.properties.subItems.forEach((subItem) => {
            if (subItem.name === checkOne) {
                subItem.attributes.forEach((attribute) => {
                    if (attribute.name === checkTwo) {
                        value = attribute.value
                    }
                })
            }
        })
        return value
    }

    checkSubTypesInclude(check) {
        const values = []
        this.properties.subItems.forEach((item) => {
            if (item.name.toLowerCase().includes(check.toLowerCase())) {
                values.push(item)
            }
        })
        return values
    }

    checkAttributesInclude(check) {
        const values = []
        this.properties.attributes.forEach((item) => {
            if (item.name.toLowerCase().includes(check.toLowerCase())) {
                values.push(item)
            }
        })
        return values
    }
}

class StringUtils {
    constructor(str) {
        this.str = str
    }

    addFileExtension(mimeType) {
        return this.str + '.' + mime.extension(mimeType)
    }
}

class DataEntities {
    constructor(dataEntities) {
        this.entities = dataEntities.map((de) => {
            const record = {}
            de.attributes.forEach((attr) => {
                record[attr.name] = parseByType(attr.type, attr.value)
            })
            return record
        })
    }
}

class DataEntity {
    constructor(dataEntity) {
        const record = {}

        dataEntity.attributes.forEach((attr) => {
            record[attr.name] = parseByType(attr.type, attr.value)
        })

        this.entity = record
    }
}

function parseByType(type, value) {
    if (type.includes('Decimal') || type.includes('Int32')) {
        return parseInt(value) || undefined
    }

    if (type.includes('Boolean')) {
        return value.toLowerCase() === 'true'
    }

    return value
}
