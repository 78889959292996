
export default {
    props: {
        languages: '',
    },
    data() {
        return {
            selected: {},
            listActive: false,
        }
    },
    created() {
        this.languages.subItems.forEach((item) => {
            if (item.attributes[2].value === 'true') {
                this.selected = item
            }
        })
        if (!this.$cookies.get('cookie-lang')) {
            this.$setCookieLang(this.selected.attributes[1].value)
        }
    },
    methods: {
        openList() {
            this.listActive = !this.listActive
        },
        closeList() {
            this.listActive = false
        },
        itemSelected(item) {
            this.selected = item
            this.closeList()
            this.$setCookieLang(item.attributes[1].value)
            this.$nuxt.$emit('changeLang')
            this.$nuxt.$emit('changeLayoutLang')
        },
    },
}
