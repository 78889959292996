import { render, staticRenderFns } from "./User.vue?vue&type=template&id=6af4a158&scoped=true"
var script = {}
import style0 from "./User.vue?vue&type=style&index=0&id=6af4a158&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6af4a158",
  null
  
)

export default component.exports