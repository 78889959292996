/* eslint-disable @typescript-eslint/no-unused-vars */
export default ({ app }, inject) => {
    inject('createSearch', createSearch)
    inject('createDropdownControl', createDropdownControl)
    inject('createDropdownstring', createDropdownstring)
}

function createSearch(search, currentOptions) {
    if (search.Date || search.Date === '') {
        if (search.Length > 1) {
            if (search.Flag === 'Invoice') {
                currentOptions.search.InvoiceDate = search.Date
            } else if (search.Flag === 'Due') {
                currentOptions.search.DueDate = search.Date
            }
        } else {
            currentOptions.search.Date = search.Date
        }
    }
    if (search.unit) {
        currentOptions.search.Weight = `WeightUnit:${search.value}||`
    }
    if (search.FreeSearch) {
        currentOptions.search.FreeSearch = `Query:${search.FreeSearch}||`
    } else if (search.FreeSearch === '') {
        currentOptions.search.FreeSearch = ``
    }

    return currentOptions
}

function createDropdownControl(dropdowns, currentOptions) {
    dropdowns.forEach((e) => {
        const item = {
            Id: e.value,
            Value: '',
        }
        currentOptions.dropdown.push(item)
    })

    return currentOptions
}

function createDropdownstring(payload, currentOptions) {
    const filters = []
    currentOptions.dropdown.find((e) => e.Id === payload.Id).Value = payload.Value
    currentOptions.dropdown.forEach((item) => {
        if (item.Value !== '') {
            filters.push(item.Value)
        }
    })
    currentOptions.dropdownFilter = filters.join('||')

    return currentOptions
}
