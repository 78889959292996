import { gql } from 'nuxt-graphql-request'

const addMessageMutation = gql`
    mutation addMessageMutation($input: MessageInput) {
        addMessage(input: $input) {
            accountNumber
        }
    }
`

export default addMessageMutation
