
import { ROLE_ESHOP } from '~/constants/roles.js'

export default {
    props: {
        items: '',
        itemName: '',
        itemPath: '',
    },
    data() {
        return {
            isActive: false,
            roles: this.$auth.user?.Role,
        }
    },
    computed: {
        isPricelist() {
            return this.itemPath.toLowerCase() === '/pricelists'
        },
    },
    created() {
        this.$nuxt.$on('openSubMenu', (name) => {
            if (name === this.itemName) {
                this.isActive === true ? (this.isActive = false) : (this.isActive = true)
            }
        })
    },
    methods: {
        checkOrderRoles(item, roles) {
            const orderSubPaths = ['orders', 'order-statistics', 'order-documents', 'order-documents']

            return orderSubPaths.includes(item.attributes[0].value.toLowerCase()) && roles.includes('hcp-orders')
        },
        checkPricelistRoles(index, roles) {
            if (index === this.items.length - 1 && !roles.includes(ROLE_ESHOP)) {
                return false
            }

            return true
        },
        isUrl(str) {
            /* eslint-disable no-useless-escape */
            const regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
            return regexp.test(str)
        },
        invokeExternalUrl(url) {
            window.open(url, '_blank', 'noreferrer')
        },
    },
}
