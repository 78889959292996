import { gql } from 'nuxt-graphql-request'

const accountsQuery = gql`
    query accountsQuery($accountId: ID, $searchFilter: String) {
        ListDataEntity(accountId: $accountId, entityType: "accounts", searchFilter: $searchFilter) {
            dataentities {
                attributes {
                    name
                    value
                    type
                }
            }
        }
    }
`

export default accountsQuery
