
export default {
    props: {
        title: '',
        innerSize: '',
        externalSize: '',
    },
    data() {
        return {
            graphQL: '',
            isLoading: true,
            loadModal: false,
            modalBg: '',
        }
    },
    methods: {
        openModal() {
            this.loadModal = true
            setTimeout(() => {
                this.isLoading = false
            }, 200)
            this.modalBg = 'side-modal__bg-active'
            document.body.classList.add('modal-open')
        },
        closeModal() {
            this.loadModal = false
            this.isLoading = true
            this.modalBg = ''
            document.body.classList.remove('modal-open')
        },
        getExternalSizeClass() {
            if (this.externalSize === 'wide') {
                return 'side-modal__container--wide'
            }

            if (this.externalSize === 'narrow') {
                return 'side-modal__container--narrow'
            }

            return ''
        },
        getInnerSizeClass() {
            if (this.innerSize === 'wide') {
                return '--wide'
            }

            return ''
        },
    },
}
