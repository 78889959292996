import { initialState } from './state.js'
import { calculateSubtotal, calculatePrice, compareByItemName } from './helpers.js'

export default {
    setBasket(state, value) {
        state.deliveryDate = value.deliveryDate
        state.productsAmount = value.productsAmount
        state.products = value.products
        state.orderRequestsAmount = value.orderRequestsAmount
        state.orderRequests = value.orderRequests
        state.acceptedTerms = value.acceptedTerms

        sessionStorage.setItem('basket', JSON.stringify(state))
    },
    setVisibleNotification(state, value) {
        state.visibleNotification = value
    },
    setShouldRefreshPrices(state, value) {
        state.shouldRefreshPrices = value
    },
    setAcceptedTerms(state, value) {
        state.acceptedTerms = value
        sessionStorage.setItem('basket', JSON.stringify(state))
    },
    setDeliveryDate(state, value) {
        state.deliveryDate = value
        sessionStorage.setItem('basket', JSON.stringify(state))
    },
    updateProductPrice(state, value) {
        const existItem = state.products.find((i) => compareByItemName(i, value))

        const newItem = {
            ...existItem,
            BasePrice: value.BasePrice,
            Surcharge: value.Surcharge,
            Currency: value.Currency,
            Unit: value.Unit,
        }

        newItem.Price = calculatePrice(newItem)
        newItem.Subtotal = calculateSubtotal(newItem)

        if (existItem) {
            const existItemIndex = state.products.findIndex((i) => compareByItemName(i, newItem))
            state.products.splice(existItemIndex, 1, newItem)
        }

        sessionStorage.setItem('basket', JSON.stringify(state))
    },
    addProduct(state, value) {
        const existItem = state.products.find((i) => compareByItemName(i, value))

        const newItem = {
            ...value,
            Price: calculatePrice(value),
            Weight: existItem ? value.Weight + existItem.Weight : value.Weight,
            Quantity: existItem ? value.Quantity + existItem.Quantity : value.Quantity,
        }

        newItem.Subtotal = calculateSubtotal(newItem)

        if (existItem) {
            const existItemIndex = state.products.findIndex((i) => compareByItemName(i, newItem))
            state.products.splice(existItemIndex, 1, newItem)
        } else {
            state.products.push(newItem)
        }

        state.productsAmount = state.products.length
        state.lastAddedItem = newItem

        sessionStorage.setItem('basket', JSON.stringify(state))
    },
    addOrderRequest(state, value) {
        const existItem = state.orderRequests.find((i) => compareByItemName(i, value))

        const newItem = {
            ...value,
            Weight: existItem ? value.Weight + existItem.Weight : value.Weight,
            Quantity: existItem ? value.Quantity + existItem.Quantity : value.Quantity,
        }

        if (existItem) {
            const existItemIndex = state.orderRequests.findIndex((i) => compareByItemName(i, newItem))
            state.orderRequests.splice(existItemIndex, 1, newItem)
        } else {
            state.orderRequests.push(newItem)
        }

        state.orderRequestsAmount = state.orderRequests.length
        state.lastAddedItem = newItem

        sessionStorage.setItem('basket', JSON.stringify(state))
    },
    removeProduct(state, value) {
        const withoutItem = state.products.filter((i) => !compareByItemName(i, value))

        state.products = withoutItem
        state.productsAmount = withoutItem.length

        sessionStorage.setItem('basket', JSON.stringify(state))
    },
    removeOrderRequest(state, value) {
        const withoutItem = state.orderRequests.filter((i) => !compareByItemName(i, value))

        state.orderRequests = withoutItem
        state.orderRequestsAmount = withoutItem.length

        sessionStorage.setItem('basket', JSON.stringify(state))
    },
    changeProductValue(state, { index, key, payload }) {
        state.products[index][key] = payload
        sessionStorage.setItem('basket', JSON.stringify(state))
    },
    changeOrderRequestValue(state, { index, key, payload }) {
        state.orderRequests[index][key] = payload
        sessionStorage.setItem('basket', JSON.stringify(state))
    },
    clearProducts(state, customerNumber) {
        if (customerNumber) {
            state.products = state.products.filter((p) => p.CustomerNumber !== customerNumber)
            state.productsAmount = state.products.length
        } else {
            state.products = []
            state.productsAmount = 0
        }
        sessionStorage.setItem('basket', JSON.stringify(state))
    },
    clearOrderRequests(state, customerNumber) {
        if (customerNumber) {
            state.orderRequests = state.orderRequests.filter((or) => or.CustomerNumber !== customerNumber)
            state.orderRequestsAmount = state.orderRequests.length
        } else {
            state.orderRequests = []
            state.orderRequestsAmount = 0
        }
        sessionStorage.setItem('basket', JSON.stringify(state))
    },
    clear(state) {
        Object.assign(state, initialState)
        state.products = []
        state.orderRequests = []
        sessionStorage.removeItem('basket')
    },
}
