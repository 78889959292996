
import accountsQuery from '~/graphql/queries/accounts-query.js'

export default {
    props: {
        profile: '',
    },
    data() {
        return {
            isActive: false,
        }
    },
    computed: {
        canSwitchAccount() {
            return this.hasSelectedAccount && (this.$auth.$state.user?.AccountNumbers.length > 1 || this.$auth.$state.user?.EntityName === 'SystemUser')
        },
        switchAccountLabel() {
            return this.profile?.subItems[0]?.subItems[0]?.attributes[1]?.value || ''
        },
        switchAccountPath() {
            return this.profile?.subItems[0]?.subItems[0]?.attributes[0]?.value || ''
        },
        hasSelectedAccount() {
            return this.$store.state.account.hasSelectedAccount
        },
        selectedAccountName() {
            return this.$store.state.account.accountName
        },
        logoutLabel() {
            return this.profile?.attributes[1]?.value || ''
        },
    },
    async created() {
        await this.validateCookies()
        this.$nuxt.$on('openProfile', () => {
            this.isActive === true ? (this.isActive = false) : (this.isActive = true)
        })
    },
    methods: {
        async logout() {
            await this.$logout()
        },
        async retrieveAccountName() {
            const graphQL = await this.$graphql.default.request(accountsQuery, { accountId: '', searchFilter: `AccountNumber:${this.$store.state.account.accountNumber}` }, this.$getAuthRequestHeader())
            const entity = graphQL.ListDataEntity.dataentities.find((entity) => entity.attributes.length > 2 && entity.attributes[2].value === this.$store.state.account.accountNumber)
            const accountName = entity.attributes.length > 1 ? entity.attributes[1].value : ''

            this.$store.commit('account/setAccountName', accountName)
            this.$setCookie('accountName', accountName)
        },
        switchAccount() {
            this.$store.commit('account/resetAccount')
            this.$removeCookie('accountNumber')
            this.$removeCookie('accountName')
            this.$store.commit('basket/clear')
            this.$store.commit('checkout/clear')
            sessionStorage.removeItem('customerData')
            this.$router.push(this.switchAccountPath)
        },
        async validateCookies() {
            if (this.$cookies.get('accountNumber')) {
                if (!this.$cookies.get('accountName')) {
                    await this.retrieveAccountName()
                }

                if (this.$cookies.get('accountName')) {
                    this.$store.commit('account/setAccountName', this.$cookies.get('accountName'))
                }
            }
        },
    },
}
