import { initialState } from './state.js'
import { PRODUCT_TYPE, ORDER_REQUEST_TYPE } from '~/constants/orderTypes.js'

export default {
    setCheckout(state, value) {
        state.deliveryAddressViewModel = value.deliveryAddressViewModel
        state.deliveryAddressEntity = value.deliveryAddressEntity
        state.invoiceAddressEntity = value.invoiceAddressEntity
        state.deliveryAddressIndex = value.deliveryAddressIndex
        state.currentType = value.currentType

        sessionStorage.setItem('checkout', JSON.stringify(state))
    },
    setDeliveryAddress(state, value) {
        state.deliveryAddressViewModel = value.viewModel
        state.deliveryAddressEntity = value.entity
        state.deliveryAddressIndex = value.index
        sessionStorage.setItem('checkout', JSON.stringify(state))
    },
    clearDeliveryAddress(state) {
        state.deliveryAddressViewModel = undefined
        state.deliveryAddressEntity = undefined
        state.deliveryAddressIndex = -1
        sessionStorage.setItem('checkout', JSON.stringify(state))
    },
    setInvoiceAddress(state, value) {
        state.invoiceAddressEntity = value
        sessionStorage.setItem('checkout', JSON.stringify(state))
    },
    setProductMode(state) {
        state.currentType = PRODUCT_TYPE
        sessionStorage.setItem('checkout', JSON.stringify(state))
    },
    setRequestMode(state) {
        state.currentType = ORDER_REQUEST_TYPE
        sessionStorage.setItem('checkout', JSON.stringify(state))
    },
    setWarningBox(state, value) {
        state.warningBox = value
    },
    clear(state) {
        Object.assign(state, initialState)
        sessionStorage.removeItem('checkout')
    },
}
